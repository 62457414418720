<template>
  <Header title="O darovaní krvi" />
  <div id="about-donation" class="container">
    <h4>Prečo darovať</h4>
    <p>Obete úrazov a nehôd, ľudia podstupujúci operácie, liečiaci sa pacienti - tí všetci potrebujú krv. Preto darovanie krvi je prejavom ľudskosti a v našej krajine sa stáva bežnou súčasťou života, ktorým sa darca dobrovoľne zúčastňuje na záchrane a liečení pacientov. Rozhodnutie stať sa darcom závisí od Vás a samozrejme aj od Vášho zdravotného stavu.</p>

    <h4>Čo je dobré vedieť</h4>
    <ul>
      <li><b>Musíte mať</b> vek od 18 do 60 rokov</li>
      <li><b>Musíte mať</b> telesnú hmotnosť minimálne 50 kg</li>
      <li><b>Nesmiete byť</b> nosičom vírusu HIV (mať AIDS)</li>
      <li><b>Nemôžete byť</b> po prekonaní hepatitídy typu B a C</li>
      <li><b>Nemôžete trpieť</b> na závažné a chronické choroby</li>
    </ul>

    <h4>Príprava</h4>
    <p>Je nutné, aby ste pred odberom mali dostatočný príjem tekutín. 24 hodín pred odberom vypite minimálne 2 litre (vodu, minerálku, čaje, ovocné šťavy). Ráno pred odberom vypite 500 ml tekutín. Čierna káva je tiež povolená.<br>
    <b>Nehladujte! Na odber krvi nechoďte nalačno!</b></p>

    <p>Viac informácii na stránke: <a href="http://www.ntssr.sk" target="_blank">www.ntssr.sk</a></p>

  </div>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/Header'

export default {
  name: 'AboutDonation',
  components: {
    Header
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "../functions.scss";

#about-donation {
  text-align: left;
  padding-bottom: 30px;
  h4 {
    font-weight: bold;
    margin-top: 25px;
    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
